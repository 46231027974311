.calendar
    max-height: 100vh
    color: #000 !important

    .fc-view-harness
        background: #fff

    .fc .fc-list-event-title a
        color: #000

    .fc .fc-toolbar-title
        @media (max-width: 1080px)
            font-size: 1em
        color: #fff
