.footer
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  gap: 1rem
  box-shadow: 0 -2px 5px rgba(255, 255, 255, 0.2)
  padding: 20px
  font-size: 0.9rem

  &__col
    @media (max-width: 800px)
      width: 100%
      text-align: center
      align-items: center

    &--center
      text-align: center
      align-items: center

  &__hour
    margin: 0

  img
    width: 200px

  a
    color: inherit
    text-decoration: none
