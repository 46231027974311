.home
  position: relative
  width: 100%
  height: 100%

  &__section
    margin: 2rem 0

  &__content
    position: relative
    z-index: 1

  &__image
    margin-bottom: 1rem
    width: 100%
    height: auto

  &__calendar-container
    position: relative
    width: 100vw
    left: 50%
    right: 50%
    transform: translateX(-50%)
    margin: 1rem 0
    padding: 0 1rem
    overflow: hidden

    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

      @media (max-width: 768px)
        min-height: 300px