.hero
    position: relative
    background-color: black
    width: 100vw
    //height: calc(100vh - var(--nav-height))
    height: 100vh
    overflow: hidden

    &__content
        position: absolute
        width: 100%
        height: 100%
        z-index: 2

    &__video-container
        animation: heroFadeOut 4s forwards
        animation-delay: 6s
        width: 100%
        height: 100%

    &__video
        object-fit: cover
        width: 100%
        height: 100%

    &__logo
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        max-width: calc(100% - 4rem)
        max-height: calc(100% - 4rem)
        aspect-ratio: 1920/970
        margin: auto
        animation: heroFadeIn 2s forwards
        animation-delay: 2s
        opacity: 0

    &__scroll-arrow
        position: absolute
        bottom: 20px
        left: 50%
        transform: translateX(-50%)
        color: gold  // Using the same gold color as the logo
        animation: HeroBounce 2s infinite

        &--stop-bounce
            animation: none
            transform: translateY(0)
            opacity: 0
            transition: opacity 2s ease

    svg
        animation: heroFadeIn 4s forwards
        width: 36px
        height: 36px

@keyframes heroFadeOut
    from
        opacity: 1
    to
        opacity: 0

@keyframes heroFadeIn
    from
        opacity: 0
    to
        opacity: 1

@keyframes HeroBounce
    0%, 20%, 50%, 80%, 100%
        transform: translateY(0)
    40%
        transform: translateY(-30px)
    60%
        transform: translateY(-15px)
